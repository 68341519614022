import React from 'react';

import Histogram from '../../components/Histogram';

import { customRound } from '../../helpers/legend';
import { useProjectContext } from '../../context/ProjectContext';

const CapebHistogram = ({ graph, total, ...props }) => {
  
  const { colors } = useProjectContext();
  
  if (!total || !total.compare) {
    return null;
  }
  
  const { compare } = total;
  
  let config = {
    title: '',
    categories: ['Année'],
    series: [
      { 
        name: compare.last.year,
        data: [customRound(compare.last.total, graph.type)],
        color: colors ? colors.ternary : '#54287f',
      },
      { 
        name: compare.last2.year,
        data: [customRound(compare.last2.total, graph.type)],
        color: colors ? colors.secondary : '#dc9124',
      },
      { 
        name: compare.current.year,
        data: [customRound(compare.current.total, graph.type)],
        color: colors ? colors.primary : '#c6362d',
      },
    ],
  };
  const params = {};
  
  switch(graph.type) {
    case 'capeb-taux-mise-oeuvre-global-dep':
    case 'capeb-taux-mise-oeuvre-global-reg':
      config.title = `Evolution ${compare.last.year} - ${compare.current.year}`;
      params.yAxis = {
        max: 100,
      };
      params.tooltip = {
        headerFormat: '',
        pointFormat: '<b>Taux de mise en oeuvre en {series.name}: </b>{point.y}%',
      };
      params.plotOptions = {
        column: {
          dataLabels: {
            format: '{point.y}%'
          }
        }
      };
      break;
    case 'capeb-taux-syndicalisation-dep':
      config.title = `Evolution ${compare.last.year} - ${compare.current.year}`;
      params.tooltip = {
        headerFormat: '',
        pointFormat: '<b>{series.name}: </b>{point.y}%',
      };
      params.plotOptions = {
        column: {
          dataLabels: {
            format: '{point.y}%'
          }
        }
      };
      break;
    case 'capeb-nombre-adherent-et-evolution-dep':
      config.title = `Evolution du nombre d'ahérents`;
      break;
    case 'capeb-nombre-collaborateur-etp-dep':
    case 'capeb-nombre-collaborateur-etp-reg':
      config.title = `Evolution du nombre de collaborateurs`;
      break;
  }
  
  return (
    <Histogram data={config} graph={graph} params={params} {...props} />  
  );
};

export default CapebHistogram;