/* global addSpacesToNumber */
import React from 'react';

import { customRound } from '../../helpers/legend';
import ResumeBlock from '../../components/ResumeBlock';

const CapebResumeBlock = ({ graph, total, ...props }) => {
  if (!total || !total.selected) {
    return null;
  }
  
  const { selected, compare } = total;
  const config = [];
  
  switch(graph.type) {
    case 'capeb-taux-mise-oeuvre-global-dep':
    case 'capeb-taux-mise-oeuvre-global-reg':
      config.push({
        title: `Taux de mise en oeuvre ${!selected.zone ? 'moyen' : ''} en ${selected.year} ${selected.zone ? ' pour la CAPEB ' + selected.zone.name : ''}`, 
        value: customRound(selected.total, graph.type), 
        label: selected.nonDefinie ? 'NR' : addSpacesToNumber(customRound(selected.total, graph.type)) + '%'
      });
      break;
    case 'capeb-nombre-adherent-et-evolution-dep':
      config.push({
        title: `Nombre d'adhérents en ${selected.year}${selected.zone ? ' pour la CAPEB ' + selected.zone.name : ''}`, 
        value: customRound(selected.total, graph.type), 
        label: addSpacesToNumber(customRound(selected.total, graph.type))
      });
      config.push({
        title: 'Evolution ' + selected.lastYear + '-' + selected.year, 
        value: customRound(selected.evolution, graph.type), 
        label: addSpacesToNumber(customRound(selected.evolution, graph.type)) + '%', 
        type: 'evolution'
      });
      break;
    case 'capeb-taux-syndicalisation-dep':
      config.push({
        title: `Taux de syndicalisation ${!selected.zone ? 'moyen' : ''} en ${selected.year} ${selected.zone ? ' pour la CAPEB ' + selected.zone.name : ''}`, 
        value: customRound(selected.percent, graph.type), 
        label: addSpacesToNumber(customRound(selected.percent, graph.type)) + '%'
      });
      break;
    case 'capeb-nombre-collaborateur-etp-dep':
    case 'capeb-nombre-collaborateur-etp-reg':
      config.push({
        title: 'Nombre de collaborateurs ETP en ' + selected.year, 
        value: customRound(selected.total, graph.type), 
        label: addSpacesToNumber(customRound(selected.total, graph.type))
      });
      break;
    case 'capeb-montant-cotisation-fixe-dep':
    case 'capeb-montant-cotisation-fixe-reg':
      config.push({
        title: `Montant moyen de la cotisation en ${selected.year}`, 
        value: customRound(selected.total, graph.type), 
        label: addSpacesToNumber(customRound(selected.total, graph.type)) + ' €'
      });
      break;
    case 'capeb-montant-cotisation-variable-dep':
    case 'capeb-montant-cotisation-variable-reg':
      config.push({
        title: `Part variable unitaire en ${selected.year}`, 
        value: customRound(selected.total, graph.type), 
        label: addSpacesToNumber(customRound(selected.total, graph.type)) + '%'
      });
      break;
  }
  
  return (
    <ResumeBlock data={config} {...props} />  
  );
};

export default CapebResumeBlock;