import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useProjectContext } from '../context/ProjectContext';

import Loader from '../components/Loader';
import NotFound from '../pages/NotFound';

import GraphCapeb from './capeb/Graph';

const Graph = () => {
  const { project, loading, setProjectSlug, graph, setGraphID } = useProjectContext();
  const { slug, id } = useParams('');
  
  // Load project info if slug change
  useEffect(() => {
    setProjectSlug(slug);
  }, [slug]);
  
  // Load graph info if graphID change
  useEffect(() => {
    setGraphID(id);
  }, [id]);
  
  const addActiveStatus = (project, graphID) => {
    if (project) {
      const rubriques = project.rubriques.map(rubrique => {
        rubrique.active = false;
        rubrique.subs = rubrique.subs.map(sub => {
          sub.active = false;
          
          sub.graphs = sub.graphs.map(graph => {
            graph.active = false;
            if (graph.id == graphID) {
              graph.active = true;
              sub.active = true;
              rubrique.active = true;
            }
            return graph;
          });
          
          return sub;
        });
        
        return rubrique;
      });
      
      return { ...project, rubriques };
    }
    
    return project;
  };
  
  const renderContent = () => {
    if (loading) {
      return (<Loader />);
    }
    
    if (!project || !graph) {
      return (<NotFound type="project" />);
      // return '';
    }
    
    return (
      <div className="ra-graph__content">
        {project.slug === 'capeb' && <GraphCapeb project={addActiveStatus(project, graph.id)} graph={graph} />}
      </div>  
    );
  };
  
  return (
    <div className={`ra-graph ${loading ? 'ra-graph--loading' : ''} ${project ? 'ra-graph--' + project.slug : ''} ${graph ? 'ra-graph--' + graph.type + ' ra-graph--' + graph.id : ''}`}>
      {renderContent()}
    </div>
  );
};

export default Graph;