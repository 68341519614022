import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import '../assets/styles/pages/NotFound.scss';

const NotFound = ({ type }) => {
  return (
    <div className="ra-not-found">
      <Container>
        <Row>
          <Col className="text-center">
            <h1 className="ra-not-found__title">Désolé, la page demandée est introuvable</h1>
            <p className="ra-not-found__description">Veuillez vérifier l'url ou revenir en arrière</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFound;