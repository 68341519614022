/* global URLSearchParams */
const axios = require('axios');
const emitter = require('./eventEmitter');

class ApiService {
  
  constructor() {
    if (ApiService.instance) {
      return ApiService.instance;
    }
    
    // Init proprieties here
    this.url = process.env.REACT_APP_API_URL; 
    
    // Init class
    ApiService.instance = this;
  }
  
  
  /**
   * Lance une requete get vers l'url spécifié
   * 
   * @param string url
   * @return Promise
   */
  _getRequest(endpoint) {
    return axios.get(this.url + endpoint, { timeout: 300000 })
    .then(response => {
      emitter.emit('api.getRequest.success', {
        event: 'api.getRequest.error',
        response: response,
        url: this.url + endpoint
      });
      return response.data;
    })
    .catch(err => {
      emitter.emit('api.getRequest.error', {
        event: 'api.getRequest.error',
        error: err,
        url: this.url + endpoint
      });
      // return err;
    });
  }
  
  
  /**
   * Retourne la liste des graphs récupérer depuis le serveur
   * 
   * @return array
   */
  async getGraphs() {
    let result = await this._getRequest('/graphs');
    let graphs = [];
    
    if (result && result.success) {
      graphs = result.data;
    }
    return graphs;
  }
  
  
  /**
   * Retourne l'objet graphique et ajoute les secteurs de ce graphique
   *
   * @param {int} id ID du graphique
   * @return object|null
   */
  async getGraph(id) {
    let result = await this._getRequest('/graph/' + id);
    
    if (!result || !result.success) {
      return null;
    }
    const graph = result.data;
    graph.sectors = await this._getRequest('/graph/' + id + '/sectors');
    
    return graph;
  }
  
  
  /**
   * Retourne le total d'un graphique
   * 
   * @param {id} int ID du graphique
   * @param {string} codeDep
   * @param {string} sector
   * @param {parent} parent
   * @param {object} params
   * @return {object|null}
   */
  async getGraphData(id, codeDep = '', sector = '', parent = '', params = {}) {
    params = params || {};
    const urlSearch = new URLSearchParams(params);
    const queryString = urlSearch.toString() ? '&' + urlSearch.toString() : '';
    let result = await this._getRequest('/graph/' + id + '/total/' + codeDep + '?sector=' + sector + '&parent=' + parent + queryString);
    
    if (!result || !result.success) {
      return null;
    }
    return result.data;
  }
  
  
  /**
   * 
   * @param {int} idGraph
   * @param {object} 
   * @return {array}
   */
  async getGraphSectors(idGraph, params = {}) {
    params = params || {};
    const urlSearch = new URLSearchParams(params);
    let result = await this._getRequest('/graph/' + idGraph + '/sectors?' + urlSearch.toString());
    let sectors = [];
    
    if (result && result.success) {
      sectors = result.data;
    }
    return sectors;
  }
  
  
  /**
   * 
   * @param {int} idGraph
   * @return {array}
   */
  async getLimitations(idGraph) {
    let result = await this._getRequest('/graph/' + idGraph + '/limitations');
    let items = [];
    
    if (result && result.success) {
      items = result.data;
    }
    return items;
  }
  
  
  /**
   * 
   * @param {string}
   * @param {int} idGraph
   * @return {object|null}
   */
  async getProjetBySlug(slug, idGraph) {
    let result = await this._getRequest('/projects?slug=' + slug);
    
    if (!result || !result.success || !result.data.length) {
      return null;
    }
    const project = result.data[0];
    
    for(let i in project.rubriques) {
      for(let j in project.rubriques[i].subs) {
        let graphs = (project.rubriques[i].subs[j].graphs || []).map(item => item.id);
        idGraph = parseInt(idGraph, 10);
        if (graphs.indexOf(idGraph) >= 0) {
          project.rubriques[i].subs[j].active = true;
          project.rubriques[i].active = true;
        }
      }
    }
    
    return project;
  }
  
  
  /**
   * 
   * @param {string} slug
   * @param {int} idGraph
   * @return {object|null}
   */
  async getProjetSectors(slug, idGraph) {
    let result = await this._getRequest('/projects/' + slug + '/sectors');
    
    if (!result || !result.success || !result.data) {
      return null;
    }
    
    return result.data;
  }
  
  
  /**
   * 
   * @param {string} slug
   * @param {string} sectorCode
   * @param {string} regionCode
   * @return {object|null}
   */
  async getPdfInfo(slug, sectorCode = '', regionCode = '', params = {}) {
    params = params || {};
    const urlSearch = new URLSearchParams(params);
    let result = await this._getRequest('/pdf/' + slug + '?sectorCode=' + sectorCode + '&regionCode=' + regionCode + (urlSearch.toString() ? '&'+ urlSearch.toString() : urlSearch.toString()));
    
    if (!result || !result.success || !result.data) {
      return null;
    }
    
    return result.data;
  }
  
  
  /**
   * 
   * @param {int} idGraph
   * @param {object} params
   * @return {array}
   */
  async getCommunes10000hbts(idGraph, params = {}) {
    params = params || {};
    const urlSearch = new URLSearchParams(params);
    let result = await this._getRequest('/graph/' + idGraph + '/communes?' + urlSearch.toString());
    let items = [];
    
    if (result && result.success) {
      items = result.data;
    }
    return items;
  }
}

module.exports = new ApiService();