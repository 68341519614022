import React from 'react';

import '../../assets/styles/components/capeb/Source.scss';

import logo from '../../assets/img/logo-ism.jpg';

const Source = ({ graph, className = '' }) => {
  return (
    <div className={`capeb-source ${className}`}>
      <div className="capeb-source__inner">
        <div className="capeb-source__block">
          <h5 className="capeb-source__title">Source</h5>
          <div className="capeb-source__text" dangerouslySetInnerHTML={{ __html: graph.creditsText }} />
        </div>
        <div className="capeb-source__traitement">
          <img src={logo} className="capeb-source__trtimg" alt="Logo ism" />
          <p className="capeb-source__trttext">Le traitement et l'analyse des données <br/>sont réalisés par l'Institut Supérieur des Métiers</p>
        </div>
      </div>
    </div>
  );
};

export default Source;