/* global $ */
import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official'

const Histogram = ({ data, graph, params={}, className = '' }) => {
  const [config, setConfig] = useState(null);
  
  useEffect(() => {
    if (!data) {
      return;
    }
    
    const { title, categories, series } = data;
    const defaultParams = {
      chart: {
        type: 'column'
      },
      title: {
        text: title,
        useHTML: true,
        style: {
          fontSize: '0.8rem',
          textAlign: 'center',
        }
      },
      xAxis: {
        categories: categories,
        crosshair: true
      },
      yAxis: {
        min: 0,
        labels: {
          // formatter: function() {
          //   return Utils.addSpacesToNumber(this.value);
          // },
        },
        title: {
          text: 'Total',
          display: false,
          enabled: false,
        },
      },
      legend: {
        align: 'center',
      },
      plotOptions: {
        column: {
          groupPadding: 0,
          // pointPadding: 0,
          // borderWidth: 3
        },
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            // formatter: function() {
            //   return Utils.addSpacesToNumber(this.y);
            // }
          },
          states: {
            hover: {
              enabled: false,
            }
          }
        }
      },
      // tooltip: {
      //   headerFormat: '',
      //   pointFormat: '<b>{point.name}: </b>{point.y}',
      //   shared: true,
      //   useHTML: true
      // },
      series: series
    };
    
    setConfig($.extend(true, {}, defaultParams, params));
  }, [data]);
  
  return (
    <React.Fragment>
      {data && (
        <div className={`ra-histogram ${className}`}>
          <div className="ra-histogram__graph">
            <HighchartsReact options={config} />
          </div>
        </div>
      )}
    </React.Fragment>
  )
};

export default Histogram;