import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useProjectContext } from '../context/ProjectContext';

import Loader from '../components/Loader';
// import NotFound from '../pages/NotFound';

import SommaireCapeb from './capeb/SommaireCapeb';

const Sommaire = () => {
  const { project, loading, setProjectSlug } = useProjectContext();
  const { slug } = useParams('');
  
  // Load project info if slug change
  useEffect(() => {
    setProjectSlug(slug);
  }, [slug]);
  
  
  // Switch content
  const switchSommaire = (project) => {
    switch(project.slug) {
      case 'capeb':
        return (<SommaireCapeb project={project} />);
      default:
        return (<h1>{ project.name }</h1>);
    }
  };
  
  // Render main content
  const renderContent = () => {
    if (loading) {
      return (<Loader />);
    }
    
    if (!project) {
      // return (<NotFound type="project" />);
      return '';
    }
    
    return (
      <div className="ra-sommaire__content">
        {switchSommaire(project)}
      </div>  
    );
  };
  
  return (
    <div className={`ra-sommaire ${loading ? 'ra-sommaire--loading' : ''} ${project ? 'ra-sommaire--' + project.slug : ''}`}>
      {renderContent()}
    </div>
  );
};

export default Sommaire;