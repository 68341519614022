import React from 'react';

const EvolutionCircle = ({ value, label }) => {
  return (
    <div className={`ra-evolution-circle ra-evolution-circle--${value > 0 ? 'plus' : (value < 0 ? 'minus' : 'equal')}`}>
      {label}
    </div>
  );
};

const ResumeBlock = ({ data, className='' }) => {
  return (
    <div className={`ra-resume-block ${className}`}>
      <div className="ra-resume-block__inner">
        {data.map(({ title, value, label, type }, i) => (
          <div key={`data-row-${i}`} className="ra-resume-block__row">
            <p className="ra-resume-block__title">{title}</p>
            <div className={`ra-resume-block__value ra-resume-block__value--${type}`}>
              {type === 'evolution' ? 
                (<EvolutionCircle value={value} label={label} />) : 
                <React.Fragment>{label}</React.Fragment>
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResumeBlock;