import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import CapebMap from '../../components/capeb/CapebMap';
import CapebResumeBlock from '../../components/capeb/CapebResumeBlock';
import CapebHistogram from '../../components/capeb/CapebHistogram';


const MapAndHistogram = ({ graph, total, className = '', ...props }) => {
  
  // -----------------------------------
  // React
  // -----------------------------------
  return (
    <div className={`${className}`} {...props}>
      <Container>
        <Row>
          <Col xs={12} md={8}>
            <CapebMap graph={graph} total={total} />
          </Col>
          <Col xs={12} md={4}>
            <CapebResumeBlock graph={graph} total={total} />
            {[
              'capeb-taux-mise-oeuvre-global-dep',
              'capeb-taux-mise-oeuvre-global-reg',
              'capeb-nombre-adherent-et-evolution-dep',
              'capeb-taux-syndicalisation-dep',
              'capeb-nombre-collaborateur-etp-dep',
              'capeb-nombre-collaborateur-etp-reg',
            ].indexOf(graph.type) >= 0 && (
              <CapebHistogram graph={graph} total={total} />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MapAndHistogram;