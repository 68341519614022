

export const customRound = (value, graphType) => {
  switch (graphType) {
    case 'capeb-montant-cotisation-variable-dep':
      return Math.round(value * 100) / 100;
    case 'capeb-montant-cotisation-variable-reg':
      return Math.round(value * 1000) / 1000;
      // break;
    default:
      return Math.round(value);
      // break;
  }
};

// -----------------------------------
// Divises une liste de valeur en 2 tableaux
// -----------------------------------
export const scindeValues = (values, graphType) => {
  let sum = 0;
  let min = 9999999;
  let max = -999999;
  values = values
    .sort(function(a, b) {
      return a - b;
    })
    .map(function(item) {
      sum += item;
      min = Math.min(item, min);
      max = Math.max(item, max);
      return item;
    });
  const moyenne = sum / values.length;
  
  return {
    inferior: values.filter(function(item) {
      return item < moyenne;
    }),
    superior: values.filter(function(item) {
      return item >= moyenne;
    }),
    average: customRound(moyenne, graphType),
    min: customRound(min, graphType),
    max: customRound(max, graphType),
  };
};