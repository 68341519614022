import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import '../../assets/styles/pages/capeb/capeb-sommaire.scss';

import logo from './logo-capeb.svg';

const Title = ({ children, className = '' }) => {
  return (
    <Row>
      <Col>
        <h2 className={`capeb-sommaire__title ${className}`}>{children}</h2>
      </Col>
    </Row>
  );
};

const SommaireCapeb = ({ project }) => {
  const [selected, setSelected] = useState('');
  
  return (
    <div className="capeb-sommaire">
      <div className="capeb-sommaire__header">
        <Container>
          <Row style={{ alignItems: 'center' }}>
            <Col xs="auto">
              <img className="capeb-sommaire__logo img-fluid" src={logo} alt="Logo" />
            </Col>
            <Col>
              <h1 className="capeb-sommaire__maintitle">Indicateurs d'activité et financiers du Réseau CAPEB</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Title>1. Sélection de l'échelon territorial</Title>
        <Row>
          {
            project.rubriques.map((rub, i) => (
              <Col key={rub.name}>
                <button className={`capeb-sommaire__button ${selected === rub.name ? 'capeb-sommaire__button--active' : ''}`} onClick={() => setSelected(rub.name)} size="lg">
                  {rub.name}
                </button>
              </Col>
            ))
          }
        </Row>
        
        {selected !== '' && (
          <React.Fragment>
            <Title>2. Sélection de l’indicateur</Title>
            {
              project.rubriques.filter(rub => selected === rub.name).map((rub, i) => {
                return (
                  <Row key={rub.name}>
                    {rub.subs.map((sub, j) => (
                      <Col key={'sub-'+j}>
                        <div className="capeb-sommaire__sub">
                          <h3 className="capeb-sommaire__subtitle">{sub.name}</h3>
                          <ul className="capeb-sommaire__graphs">
                            {sub.graphs.map((graph) => (
                              <li className="capeb-sommaire__graph" key={graph.id}>
                                <Link to={`/projet/${project.slug}/${graph.id}`}>{graph.name}</Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Col>
                    ))}
                  </Row>
                );
              })
            }
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

export default SommaireCapeb;