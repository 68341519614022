// create a singleton instance of the EventEmitter for the entire application

const EventEmitter = require('events');

class MyEmitter extends EventEmitter {

  static getInstance() {
    if (!this.instance) {
      this.instance = new MyEmitter();
    }
    return this.instance;
  }
}

module.exports = MyEmitter.getInstance();