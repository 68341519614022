import React from 'react';

import Map from '../Map';

const MapReg = ({ config, className='', ...props }) => {
  const mapID = 'france_capeb_reg';
  const mapUrl = 'france/reg/france_capeb_reg';
  
  return (
    <Map config={config} mapID={mapID} mapUrl={mapUrl} className={`ra-map ${className}`} {...props} />
  );
};

export default MapReg;