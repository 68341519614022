import React from 'react';

import '../assets/styles/components/Loader.scss';

const Loader = () => {
  return (
    <div className="loader">
      <div className="loader__inner">
        <div className="loader__spinner" />
        <div className="loader__text">
          Chargement ...
        </div>
      </div>
    </div>
  );
};

export default Loader;