// --------------------------------------------------------
// Fonction permettant de générer une couleur selon le pourcentage envoyé en commentaire
// 
// @param string hex Couleur de départ (Couleur=100%)
// @param int Pourcentage
// @return string Couleur hexadécimale
// --------------------------------------------------------
export const increaseBrightness = (hex, percent) => {
  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length == 3) {
    hex = hex.replace(/(.)/g, '$1$1');
  }

  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  return '#' +
     ((0|(1<<8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
     ((0|(1<<8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
     ((0|(1<<8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
}


// --------------------------------------------------------
// Permet de générer une couleur intermédiaire à partir d'une valeur, une limite et une couleur de départ
// 
// @param int current Valeur actuelle
// @param int max Valeur maximale
// @param string color Couleur hexadécimale correspondant à la valeur maximale (steps)

// @return string Coloue intermédiaire représentant la valeur (value) par rapport à la valuer steps 
// --------------------------------------------------------
export const stepColor = (current, max, color) => {
  var percent = Math.round((max-current) / max * 100);
  return increaseBrightness(color, percent);
};