import React from 'react';

import Map from '../Map';

const MapDep = ({ config, className='', ...props }) => {
  const mapID = 'france_capeb_dep';
  const mapUrl = 'france/dep/france_capeb_dep';
  
  return (
    <Map config={config} mapID={mapID} mapUrl={mapUrl} className={`ra-map ${className}`} {...props} />
  );
};

export default MapDep;