import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import '../../assets/styles/components/capeb/PageTitle.scss';

const PageTitle = ({ children, className = '' }) => {
  return (
    <div className={`capeb-page-title ${className}`}>
      <Container>
        <Row>
          <Col>
            <h1 className={`capeb-page-title__text`}>{children}</h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PageTitle;